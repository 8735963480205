@import '../../fsco-shared/src/styles/global';

// Add these styles to ensure proper modal positioning and backdrop
.custom-dialog {
    .mat-mdc-dialog-container {
        padding: 0;
        border-radius: 0.5rem;
        overflow: hidden;
    }
}

.cdk-overlay-container {
    z-index: 1000;
}

.cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: fixed;
    z-index: 1000;
}

.mat-mdc-dialog-container {
    max-width: 100vw !important;
    max-height: 100vh !important;
}

// Add to your existing styles
.modal-fullscreen {
    .mat-mdc-dialog-container {
        padding: 0;
        background: transparent;
        box-shadow: none;
    }
}

.cdk-overlay-pane.modal-fullscreen {
    max-width: 100vw !important;
}

// Remove any conflicting styles
.custom-dialog {
    display: none;
}
